import Icon from 'src/components/ui/Icon'

interface Props {
  className?: string
  text?: string
  icon?: boolean
}
const StockAvailable = ({ className, text, icon }: Props) => {
  return (
    <div className={`stock__available ${className ?? ''}`}>
      {icon && (
        <div>
          <Icon width="24px" height="24px" name="Stock" />
        </div>
      )}
      {text ? (
        <p>{text}</p>
      ) : (
        <p>Productos sujetos a disponibilidad de stock.</p>
      )}
    </div>
  )
}

export default StockAvailable
