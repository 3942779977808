import type { FC } from 'react'

export const NewsletterIcon: FC<IconProps> = () => (
  <svg
    width="118"
    height="100"
    viewBox="0 0 118 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <path
        d="M18.5014 21.495C17.6587 20.7809 16.3967 20.8851 15.6825 21.7278C14.9684 22.5705 15.0726 23.8325 15.9153 24.5466L18.5014 21.495ZM65.9532 52.5449L64.6602 51.0191L65.9532 52.5449ZM102.085 24.5466C102.927 23.8325 103.032 22.5705 102.317 21.7278C101.603 20.8851 100.341 20.7809 99.4986 21.495L102.085 24.5466ZM19.6667 22.9375H98.3333V18.9375H19.6667V22.9375ZM101.25 25.1042V75.1042H105.25V25.1042H101.25ZM98.3333 77.2708H19.6667V81.2708H98.3333V77.2708ZM16.75 75.1042V25.1042H12.75V75.1042H16.75ZM19.6667 77.2708C17.7327 77.2708 16.75 76.0032 16.75 75.1042H12.75C12.75 78.8075 16.1698 81.2708 19.6667 81.2708V77.2708ZM101.25 75.1042C101.25 76.0032 100.267 77.2708 98.3333 77.2708V81.2708C101.83 81.2708 105.25 78.8075 105.25 75.1042H101.25ZM98.3333 22.9375C100.267 22.9375 101.25 24.2051 101.25 25.1042H105.25C105.25 21.4009 101.83 18.9375 98.3333 18.9375V22.9375ZM19.6667 18.9375C16.1698 18.9375 12.75 21.4009 12.75 25.1042H16.75C16.75 24.2051 17.7327 22.9375 19.6667 22.9375V18.9375ZM15.9153 24.5466L50.7537 54.0707L53.3398 51.0191L18.5014 21.495L15.9153 24.5466ZM67.2463 54.0707L102.085 24.5466L99.4986 21.495L64.6602 51.0191L67.2463 54.0707ZM50.7537 54.0707C55.34 57.9574 62.66 57.9574 67.2463 54.0707L64.6602 51.0191C61.5661 53.6412 56.4339 53.6412 53.3398 51.0191L50.7537 54.0707Z"
        fill="#E5002B"
      />
    </g>
  </svg>
)
