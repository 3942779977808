import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useSession } from 'src/sdk/session'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import Newsletter from 'src/components/sections/Newsletter'
import '../../styles/pages/preguntas-frequentes.scss'

import Accordion, { AccordionItem } from 'src/components/ui/Accordion'
import { useState } from 'react'

interface Props {
  data: TAllCmsInstitutional
  location: {
    pathname: string
  }
}
interface TAllCmsInstitutional {
  allCmsInstitutionalPage: TEdges
  site: {
    siteMetadata: SiteSiteMetadata
  }
}

type TEdges = {
  edges: Array<{ node: { sections: Array<{ data: { iframe: string } }> } }>
}

const Page = ({ location, data: { site } }: Props) => {
  const { locale } = useSession()
  const title = site?.siteMetadata?.title ?? ''
  const [indicesExpanded, setIndicesExpanded] = useState<Set<number>>(
    new Set([])
  )
  const onChange = (index: number) => {
    if (indicesExpanded.has(index)) {
      indicesExpanded.delete(index)
      setIndicesExpanded(new Set(indicesExpanded))
    } else {
      setIndicesExpanded(new Set(indicesExpanded.add(index)))
    }
  }

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <div style={{ paddingTop: '16px' }} />

      <section className="preguntas-container">
        <div className="title-container-preguntas">
          <h2>Preguntas Frecuentes</h2>
          <div className="black-rectangle"></div>
        </div>
        <Accordion expandedIndices={indicesExpanded} onChange={onChange}>
          <AccordionItem
            isExpanded={indicesExpanded.has(0)}
            buttonLabel={'¿Hacen envíos a todo el país?'}
          >
            <div>
              <span>
                De momento hacemos envíos únicamente a las siguientes
                localidades: •
              </span>

              <ul>
                <li>Montevideo, Las Piedras, La Paz y Ciudad de la costa</li>
                <li>Maldonado</li>
                <li>Salto</li>
                <li>Paysandú</li>
                <li>Tacuarembó</li>
                <li>Mercedes</li>
                <li>Colonia</li>
                <li>Melo</li>
                <li>Duraznos</li>
                <li>Minas</li>
                <li>Florida</li>
                <li>Rivera</li>
                <li>Artigas</li>
              </ul>
              <span>
                Seguimos creciendo día a día y esperamos incorporar nuevas zonas
                de entrega próximamente.
              </span>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(1)}
            buttonLabel={'¿Puedo comprar desde cualquier parte del país?'}
          >
            <div>
              <p>
                Sí, podés comprar desde cualquier parte del país, pero la
                entrega del pedido deberá ser dentro de las zonas de envío
                habilitadas.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(2)}
            buttonLabel={'¿Cuáles son los servicios de Entrega/Retiro?'}
          >
            <div>
              <span className="first-text">
                Al momento de finalizar tu compra podrás optar por recibir tu
                pedido a domicilio o retirarlo sin costo en una de nuestras
                sucursales habilitadas.
              </span>
              <span className="location-title">
                Montevideo, Las Piedras, La Paz y Ciudad de la costa
              </span>
              <p>
                <span className="red-text">1. Envío a domicilio.</span> De lunes
                a domingo de 10 h. a 23 h.{' '}
                <span className="bold-text">Costo</span> $239.
              </p>
              <p>ENVÍO GRATIS por compras superiores a $3.490</p>
              <p>
                <span className="bold-text">Plazo de entrega. </span>Para
                recibir tu pedido a domicilio en el día debes generar tu compra
                antes de las 17h.
              </p>
              <p>
                {' '}
                <span className="italic-text">
                  Cupos de envío y retiro sujetos a disponibilidad.
                </span>
              </p>
              <p>
                <span className="red-text">2. Retiro en Local.</span>Al
                finalizar tu pedido seleccioná el local donde vas a retirar.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Para retirar tu compra deberás presentar el número de pedido o
                documento de la persona que realizó la compra en el mostrador de
                Atención al Cliente.
              </p>
              <p>
                <span className="bold-text">Horario de retiro.</span>De Lunes a
                Domingo en los locales habilitados.
              </p>
              <p>
                <span className="bold-text">Locales Habilitados</span>
              </p>
              <p>
                <span className="bold-text">
                  Franja horaria de retiro 10 h. a 12 h.
                </span>
              </p>
              <p>
                <span className="bold-text">Pocitos.</span>Cavia 2610.
              </p>
              <p>
                <span className="bold-text">
                  Franja horaria de retiro 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Carrasco. Cooper 2390. Malvin Alto. Cno. Carrasco 4654. La
                  Blanqueada. Av. 8 de Octubre 3340. Miguelete. Miguelete 2226.
                  Paso Molino. Av. Agraciada 4214. Veracierto. Veracierto 3125.
                  Solymar. Av Giannatasio y Av Uruguay.
                </span>
              </p>
              <p>
                <span className="red-text">3. AutoRetiro.</span>
              </p>
              <p>
                Comprá online y retiralo en nuestros locales, sin bajarte del
                auto y sin costo adicional.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Al momento de retirar tu pedido en el local seleccionado,
                estacioná en el lugar señalizado y envía el número de tu
                documento de identidad o el número de pedido al WhatsApp
                indicado para recibir tu compra. Recordá presentar tu cédula de
                identidad.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De Lunes a Domingo en los locales
                  habilitados.
                </span>
              </p>
              <p>
                <span className="bold-text">Locales habilitados</span>
              </p>
              <p>
                <span className="bold-text">
                  Franja horaria de retiro 12 h. a 21 h
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Carrasco. Cooper 2390 | ( 598) 99 121 314. Malvin Alto. Cno.
                  Carrasco 4654 | ( 598) 98 98 461 353. La Blanqueada. Av. 8 de
                  Octubre 3340 | ( 598) 91 276 809. Miguelete. Miguelete 2226 |
                  ( 598) 91 317 549. Solymar. Av Giannatasio y Av. Uruguay | (
                  598) 91 438 911.
                </span>
              </p>
              <span className="location-title">Maldonado</span>
              <p>
                <span className="red-text">1. Envío a domicilio. </span>De lunes
                a domingo de 10 h. a 21 h. Costo ZONA A $99 - Costo ZONA B $199
              </p>
              <p>ENVÍO GRATIS por compras superiores a $3.490</p>
              <p>
                <span className="red-text">2. Retiro en Local.</span>Al
                finalizar tu pedido seleccioná el local donde vas a retirar.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Para retirar tu compra deberás presentar el número de pedido o
                documento de la persona que realizó la compra en el mostrador de
                Atención al Cliente.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local Habilitado. Maldonado. 25 de mayo 600
                </span>
              </p>
              <p>
                <span className="red-text">3. AutoRetiro.</span> Comprá online y
                retiralo en nuestros locales, sin bajarte del auto y sin costo
                adicional.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Al momento de retirar tu pedido en el local seleccionado,
                estacioná en el lugar señalizado y envía el número de tu
                documento de identidad o el número de pedido al WhatsApp
                indicado para recibir tu compra. Recordá presentar tu cédula de
                identidad.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local habilitado. Maldonado. 25 de mayo 600.
                </span>
              </p>
              <span className="location-title">Tacuarembó</span>
              <p>
                <span className="red-text">1. Envío a domicilio.</span> De lunes
                a domingo de 10 h. a 21 h. Costo $99
              </p>
              <p>ENVÍO GRATIS por compras superiores a $3.490</p>
              <p>
                <span className="bold-text">Plazo de entrega.</span>Para recibir
                tu pedido a domicilio en el día debes generar tu compra antes de
                las 17h. Cupos de envío y retiro sujetos a disponibilidad.
              </p>
              <p>
                <span className="red-text">2. Retiro en Local.</span> Al
                finalizar tu pedido seleccioná el local donde vas a retirar.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Para retirar tu compra deberás presentar el número de pedido o
                documento de la persona que realizó la compra en el mostrador de
                Atención al Cliente.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local Habilitado. Tacuarembó. Joaquín Suarez 259.
                </span>
              </p>
              <p>
                <span className="red-text">3. AutoRetiro.</span> Comprá online y
                retiralo en nuestros locales, sin bajarte del auto y sin costo
                adicional.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Al momento de retirar tu pedido en el local seleccionado,
                estacioná en el lugar señalizado y envía el número de tu
                documento de identidad o el número de pedido al WhatsApp
                indicado para recibir tu compra. Recordá presentar tu cédula de
                identidad.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
                <span className="bold-text">
                  Local habilitado. Tacuarembó. Joaquín Suarez 259.
                </span>
              </p>
              <span className="location-title">Salto</span>
              <p>
                <span className="red-text">1. Envío a domicilio.</span> De lunes
                a domingo de 10 h. a 21 h.{' '}
                <span className="bold-text">Costo</span> $99
              </p>
              <p>
                {' '}
                <span>ENVÍO GRATIS por compras superiores a $3.490</span>
              </p>
              <p>
                <span className="bold-text">Plazo de entrega.</span> Para
                recibir tu pedido a domicilio en el día debes generar tu compra
                antes de las 17h. Cupos de envío y retiro sujetos a
                disponibilidad.
              </p>
              <p>
                <span className="red-text">2. Retiro en Local. </span>Al
                finalizar tu pedido seleccioná el local donde vas a retirar.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Para retirar tu compra deberás presentar el número de pedido o
                documento de la persona que realizó la compra en el mostrador de
                Atención al Cliente.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local Habilitado. Salto. Doctor Fco. Soca 220.
                </span>
              </p>
              <p>
                <span className="red-text">3. AutoRetiro. </span>Comprá online y
                retiralo en nuestros locales, sin bajarte del auto y sin costo
                adicional.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Al momento de retirar tu pedido en el local seleccionado,
                estacioná en el lugar señalizado y envía el número de tu
                documento de identidad o el número de pedido al WhatsApp
                indicado para recibir tu compra. Recordá presentar tu cédula de
                identidad.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local habilitado. Tacuarembó. Joaquín Suarez 259.
                </span>
              </p>
              <span className="location-title">Paysandú</span>
              <p>
                <span className="red-text">1. Envío a domicilio.</span> De lunes
                a domingo de 10 h. a 21 h.{' '}
                <span className="bold-text">Costo</span> $99
              </p>
              <p>ENVÍO GRATIS por compras superiores a $3.490</p>
              <p>
                <span className="bold-text">Plazo de entrega. </span>Para
                recibir tu pedido a domicilio en el día debes generar tu compra
                antes de las 17h. Cupos de envío y retiro sujetos a
                disponibilidad.
              </p>
              <p>
                <span className="red-text">2. Retiro en Local. </span> Al
                finalizar tu pedido seleccioná el local donde vas a retirar.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Para retirar tu compra deberás presentar el número de pedido o
                documento de la persona que realizó la compra en el mostrador de
                Atención al Cliente.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local Habilitado. Paysandú. Gral. Artigas 770.
                </span>
              </p>
              <p>
                <span className="red-text">3. AutoRetiro. </span>Comprá online y
                retiralo en nuestros locales, sin bajarte del auto y sin costo
                adicional.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Al momento de retirar tu pedido en el local seleccionado,
                estacioná en el lugar señalizado y envía el número de tu
                documento de identidad o el número de pedido al WhatsApp
                indicado para recibir tu compra. Recordá presentar tu cédula de
                identidad.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local habilitado. Paysandú. Gral. Artigas 770.
                </span>
              </p>
              <span className="location-title">Mercedes</span>
              <p>
                <span className="red-text">1. Envío a domicilio.</span> De lunes
                a domingo de 10 h. a 21 h.{' '}
                <span className="bold-text">Costo</span> $99
              </p>
              <p>ENVÍO GRATIS por compras superiores a $3.490</p>
              <p>
                <span className="bold-text">
                  Plazo de entrega. Para recibir tu pedido a domicilio en el día
                  debes generar tu compra antes de las 17h. Cupos de envío y
                  retiro sujetos a disponibilidad.
                </span>
              </p>
              <p>
                <span className="red-text">2. Retiro en Local.</span>Al
                finalizar tu pedido seleccioná el local donde vas a retirar.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Para retirar tu compra deberás presentar el número de pedido o
                documento de la persona que realizó la compra en el mostrador de
                Atención al Cliente.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local Habilitado. Mercedes. Don Bosco 734.
                </span>
              </p>

              <span className="location-title">Colonia</span>
              <p>
                <span className="red-text">1. Envío a domicilio.</span> De lunes
                a domingo de 10 h. a 21 h.{' '}
                <span className="bold-text">Costo</span> $99
              </p>
              <p>ENVÍO GRATIS por compras superiores a $3.490</p>
              <p>
                <span className="bold-text">
                  Plazo de entrega. Para recibir tu pedido a domicilio en el día
                  debes generar tu compra antes de las 17h. Cupos de envío y
                  retiro sujetos a disponibilidad.
                </span>
              </p>
              <p>
                <span className="red-text">2. Retiro en Local.</span>Al
                finalizar tu pedido seleccioná el local donde vas a retirar.
              </p>
              <p>
                <span className="italic-text">SERVICIO SIN COSTO.</span>
              </p>
              <p>
                Para retirar tu compra deberás presentar el número de pedido o
                documento de la persona que realizó la compra en el mostrador de
                Atención al Cliente.
              </p>
              <p>
                <span className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </span>
              </p>
              <p>
                <span className="bold-text">
                  Local Habilitado. Colonia del Sacramento. Av. Roosevelt esq.
                  Luis Casanello. Colonia.
                </span>
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(3)}
            buttonLabel={
              '¿Cómo puedo saber si mi domicilio se encuentra dentro de la zona de entrega?'
            }
          >
            <div>
              <p>
                Consultá el área de cobertura de tu localidad en este listado
              </p>

              <div>
                <span className="location-title">
                  Montevideo, Las Piedras, La Paz y Ciudad de la costa
                </span>
                <p>
                  <span className="red-text">1. Envío a domicilio.</span> De
                  lunes a domingo de 10 h. a 22 h.{' '}
                  <span className="bold-text">Costo </span>$239.
                </p>

                <p>ENVÍO GRATIS por compras superiores a $3.490</p>
                <p>
                  <span className="bold-text">Cobertura de entrega.</span>
                </p>

                <img
                  src="https://tatauy.vteximg.com.br/arquivos/Mapa_Montevideo_670x471.jpg?v=637630779176330000"
                  alt="Montevideo mapa"
                />

                <p>
                  <span className="bold-text">Plazo de entrega.</span>Para
                  recibir tu pedido a domicilio en el día debes generar tu
                  compra antes de las 17h.
                </p>
                <p>
                  <span className="italic-text">
                    Cupos de envío y retiro sujetos a disponibilidad.
                  </span>
                </p>

                <div>
                  <p>
                    <span className="red-text">2. Retiro en Local. </span>Al
                    finalizar tu pedido seleccioná el local donde vas a retirar.
                  </p>
                  <p>
                    <span className="italic-text">SERVICIO SIN COSTO.</span>
                  </p>
                  <p>
                    Para retirar tu compra deberás presentar el número de pedido
                    o documento de la persona que realizó la compra en el
                    mostrador de Atención al Cliente.
                  </p>
                  <p>
                    <span className="bold-text">Horario de retiro.</span> De
                    Lunes a Domingo en los locales habilitados.
                  </p>
                  <p>
                    <span className="bold-text">Locales Habilitados</span>
                  </p>
                  <p>
                    <span className="bold-text">
                      Franja horaria de retiro 10 h. a 12 h.
                    </span>
                  </p>
                  <p>
                    <span className="bold-text">Pocitos. Cooper 2390</span>
                  </p>{' '}
                  <p>
                    <span className="bold-text">
                      Franja horaria de retiro 12 h. a 21 h.
                    </span>
                  </p>{' '}
                  <p>
                    <span className="bold-text">
                      Carrasco. Cooper 2390. Malvin Alto. Cno. Carrasco 4654. La
                      Blanqueada. Av. 8 de Octubre 3340. Miguelete. Miguelete
                      2226. Paso Molino. Av. Agraciada 4214. Veracierto.
                      Veracierto 3125. Solymar. Av Giannatasio y Av Uruguay.
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <span className="location-title">Maldonado</span>
                <p>
                  <span className="red-text">1. Envío a domicilio. </span>{' '}
                  <span className="bold-text">
                    De lunes a domingo de 10 h. a 21 h. Costo $199
                  </span>
                </p>
                <p>
                  <span className="bold-text">
                    <span className="italic-text">ENVÍO GRATIS</span> por
                    compras superiores a $3.490
                  </span>
                </p>
                <p className="bold-text">Cobertura de entrega.</p>

                <img
                  src="https://tatauy.vteximg.com.br/arquivos/Mapa_Maldonado_670x471.jpg?v=637630782553730000"
                  alt="Maldonado mapa"
                />

                <p className="">
                  <span className="red-text">2. Retiro en Local. </span>{' '}
                  <span className="bold-tex">
                    {' '}
                    Al finalizar tu pedido seleccioná el local donde vas a
                    retirar.
                  </span>
                </p>
                <p className="bold-text">SERVICIO SIN COSTO.</p>
                <p className="bold-text">
                  Para retirar tu compra deberás presentar el número de pedido o
                  documento de la persona que realizó la compra en el mostrador
                  de Atención al Cliente.
                </p>
                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local Habilitado. Maldonado. 25 de mayo 600
                </p>

                <p>
                  <span className="red-text">3. AutoRetiro.</span>{' '}
                  <span className="bold-text">
                    Comprá online y retiralo en nuestros locales, sin bajarte
                    del auto y sin costo adicional.
                  </span>
                </p>

                <p className="bold-text">SERVICIO SIN COSTO.</p>

                <p className="bold-text">
                  Al momento de retirar tu pedido en el local seleccionado,
                  estacioná en el lugar señalizado y envía el número de tu
                  documento de identidad o el número de pedido al WhatsApp
                  indicado para recibir tu compra. Recordá presentar tu cédula
                  de identidad.
                </p>

                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local habilitado. Maldonado. 25 de mayo 600.
                </p>
              </div>

              <div>
                <span className="location-title">Tacuarembó</span>
                <p>
                  <span className="red-text">1. Envío a domicilio. </span>{' '}
                  <span className="bold-text">
                    De lunes a domingo de 10 h. a 21 h. Costo $99
                  </span>
                </p>
                <p>
                  <span className="bold-text">
                    <span className="italic-text">ENVÍO GRATIS</span> por
                    compras superiores a $3.490
                  </span>
                </p>
                <p className="bold-text">Cobertura de entrega.</p>

                <img
                  src="https://tatauy.vteximg.com.br/arquivos/Mapa_Tacuaremb%C3%B3_670x619.jpg?v=637630783778500000"
                  alt="Tacuarembó mapa"
                />

                <p className="bold-text">
                  Plazo de entrega. Para recibir tu pedido a domicilio en el día
                  debes generar tu compra antes de las 17h. Cupos de envío y
                  retiro sujetos a disponibilidad.
                </p>

                <p className="">
                  <span className="red-text">2. Retiro en Local. </span>{' '}
                  <span className="bold-tex">
                    {' '}
                    Al finalizar tu pedido seleccioná el local donde vas a
                    retirar.
                  </span>
                </p>
                <p className="bold-text">SERVICIO SIN COSTO.</p>
                <p className="bold-text">
                  Para retirar tu compra deberás presentar el número de pedido o
                  documento de la persona que realizó la compra en el mostrador
                  de Atención al Cliente.
                </p>
                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local Habilitado. Tacuarembó. Joaquín Suarez 259.
                </p>

                <p>
                  <span className="red-text">3. AutoRetiro.</span>{' '}
                  <span className="bold-text">
                    Comprá online y retiralo en nuestros locales, sin bajarte
                    del auto y sin costo adicional.
                  </span>
                </p>

                <p className="bold-text">SERVICIO SIN COSTO.</p>

                <p className="bold-text">
                  Al momento de retirar tu pedido en el local seleccionado,
                  estacioná en el lugar señalizado y envía el número de tu
                  documento de identidad o el número de pedido al WhatsApp
                  indicado para recibir tu compra. Recordá presentar tu cédula
                  de identidad.
                </p>

                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local habilitado. Tacuarembó. Joaquín Suarez 259.
                </p>
              </div>

              <div>
                <span className="location-title">Salto</span>
                <p>
                  <span className="red-text">1. Envío a domicilio. </span>{' '}
                  <span className="bold-text">
                    De lunes a domingo de 10 h. a 21 h. Costo $99
                  </span>
                </p>
                <p>
                  <span className="bold-text">
                    <span className="italic-text">ENVÍO GRATIS</span> por
                    compras superiores a $3.490
                  </span>
                </p>
                <p className="bold-text">Cobertura de entrega.</p>

                <img
                  src="https://tatauy.vteximg.com.br/arquivos/Mapa_Salto_670x471.jpg?v=637630781243100000"
                  alt="Salto mapa"
                />

                <p className="bold-text">
                  Plazo de entrega. Para recibir tu pedido a domicilio en el día
                  debes generar tu compra antes de las 17h. Cupos de envío y
                  retiro sujetos a disponibilidad.
                </p>

                <p className="">
                  <span className="red-text">2. Retiro en Local. </span>{' '}
                  <span className="bold-tex">
                    {' '}
                    Al finalizar tu pedido seleccioná el local donde vas a
                    retirar.
                  </span>
                </p>
                <p className="bold-text">SERVICIO SIN COSTO.</p>
                <p className="bold-text">
                  Para retirar tu compra deberás presentar el número de pedido o
                  documento de la persona que realizó la compra en el mostrador
                  de Atención al Cliente.
                </p>
                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local Habilitado. Salto. Doctor Fco. Soca 220.
                </p>

                <p>
                  <span className="red-text">3. AutoRetiro.</span>{' '}
                  <span className="bold-text">
                    Comprá online y retiralo en nuestros locales, sin bajarte
                    del auto y sin costo adicional.
                  </span>
                </p>

                <p className="bold-text">SERVICIO SIN COSTO.</p>

                <p className="bold-text">
                  Al momento de retirar tu pedido en el local seleccionado,
                  estacioná en el lugar señalizado y envía el número de tu
                  documento de identidad o el número de pedido al WhatsApp
                  indicado para recibir tu compra. Recordá presentar tu cédula
                  de identidad.
                </p>

                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local habilitado. Tacuarembó. Joaquín Suarez 259.
                </p>
              </div>

              <div>
                <span className="location-title">Paysandú</span>
                <p>
                  <span className="red-text">1. Envío a domicilio. </span>{' '}
                  <span className="bold-text">
                    De lunes a domingo de 10 h. a 21 h. Costo $99
                  </span>
                </p>
                <p>
                  <span className="bold-text">
                    <span className="italic-text">ENVÍO GRATIS</span> por
                    compras superiores a $3.490
                  </span>
                </p>
                <p className="bold-text">Cobertura de entrega.</p>

                <img
                  src="https://tatauy.vteximg.com.br/arquivos/Mapa_Paysand%C3%BA_670x471.jpg?v=637630783864630000"
                  alt="Paysandú mapa"
                />

                <p className="bold-text">
                  Plazo de entrega. Para recibir tu pedido a domicilio en el día
                  debes generar tu compra antes de las 17h. Cupos de envío y
                  retiro sujetos a disponibilidad.
                </p>

                <p className="">
                  <span className="red-text">2. Retiro en Local. </span>{' '}
                  <span className="bold-tex">
                    {' '}
                    Al finalizar tu pedido seleccioná el local donde vas a
                    retirar.
                  </span>
                </p>
                <p className="bold-text">SERVICIO SIN COSTO.</p>
                <p className="bold-text">
                  Para retirar tu compra deberás presentar el número de pedido o
                  documento de la persona que realizó la compra en el mostrador
                  de Atención al Cliente.
                </p>
                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local Habilitado. Paysandú. Gral. Artigas 770.
                </p>

                <p>
                  <span className="red-text">3. AutoRetiro.</span>{' '}
                  <span className="bold-text">
                    Comprá online y retiralo en nuestros locales, sin bajarte
                    del auto y sin costo adicional.
                  </span>
                </p>

                <p className="bold-text">SERVICIO SIN COSTO.</p>

                <p className="bold-text">
                  Al momento de retirar tu pedido en el local seleccionado,
                  estacioná en el lugar señalizado y envía el número de tu
                  documento de identidad o el número de pedido al WhatsApp
                  indicado para recibir tu compra. Recordá presentar tu cédula
                  de identidad.
                </p>

                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local habilitado. Paysandú. Gral. Artigas 770.
                </p>
              </div>

              <div>
                <span className="location-title">Mercedes</span>
                <p>
                  <span className="red-text">1. Envío a domicilio. </span>{' '}
                  <span className="bold-text">
                    De lunes a domingo de 10 h. a 21 h. Costo $99
                  </span>
                </p>
                <p>
                  <span className="bold-text">
                    <span className="italic-text">ENVÍO GRATIS</span> por
                    compras superiores a $3.490
                  </span>
                </p>
                <p className="bold-text">Cobertura de entrega.</p>

                <img
                  src="https://tatauy.vteximg.com.br/arquivos/Mapa_Mercedes_670x587.jpg?v=637630784276100000"
                  alt="Mercedes mapa"
                />

                <p className="bold-text">
                  Plazo de entrega. Para recibir tu pedido a domicilio en el día
                  debes generar tu compra antes de las 17h. Cupos de envío y
                  retiro sujetos a disponibilidad.
                </p>

                <p className="">
                  <span className="red-text">2. Retiro en Local. </span>{' '}
                  <span className="bold-tex">
                    {' '}
                    Al finalizar tu pedido seleccioná el local donde vas a
                    retirar.
                  </span>
                </p>
                <p className="bold-text">SERVICIO SIN COSTO.</p>
                <p className="bold-text">
                  Para retirar tu compra deberás presentar el número de pedido o
                  documento de la persona que realizó la compra en el mostrador
                  de Atención al Cliente.
                </p>
                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local Habilitado. Mercedes. Don Bosco 734.
                </p>
              </div>

              <div>
                <span className="location-title">Colonia</span>
                <p>
                  <span className="red-text">1. Envío a domicilio. </span>{' '}
                  <span className="bold-text">
                    De lunes a domingo de 10 h. a 21 h. Costo $99
                  </span>
                </p>
                <p>
                  <span className="bold-text">
                    <span className="italic-text">ENVÍO GRATIS</span> por
                    compras superiores a $3.490
                  </span>
                </p>
                <p className="bold-text">Cobertura de entrega.</p>

                <img
                  src="https://tatauy.vteximg.com.br/arquivos/Mapa_Colonia_670x471.jpg?v=637630784216970000"
                  alt="Colonia mapa"
                />

                <p className="bold-text">
                  Plazo de entrega. Para recibir tu pedido a domicilio en el día
                  debes generar tu compra antes de las 17h. Cupos de envío y
                  retiro sujetos a disponibilidad.
                </p>

                <p className="">
                  <span className="red-text">2. Retiro en Local. </span>{' '}
                  <span className="bold-tex">
                    {' '}
                    Al finalizar tu pedido seleccioná el local donde vas a
                    retirar.
                  </span>
                </p>
                <p className="bold-text">SERVICIO SIN COSTO.</p>
                <p className="bold-text">
                  Para retirar tu compra deberás presentar el número de pedido o
                  documento de la persona que realizó la compra en el mostrador
                  de Atención al Cliente.
                </p>
                <p className="bold-text">
                  Horario de retiro. De lunes a domingo de 12 h. a 21 h.
                </p>

                <p className="bold-text">
                  Local Habilitado. Colonia del Sacramento. Av. Roosevelt esq.
                  Luis Casanello. Colonia.
                </p>
              </div>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(4)}
            buttonLabel={'¿Cómo hago para retirar el pedido en el local?'}
          >
            <div>
              <p>
                Al momento de retirar tu pedido en el local seleccionado, se
                deberá presentar el número de pedido o cédula de identidad de la
                persona que realizó la compra en el mostrador de Atención al
                cliente.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(5)}
            buttonLabel={
              '¿Cómo hago si quiero hacer mi retiro de mi pedido desde el auto?'
            }
          >
            <div>
              <p>
                Al momento de acercarte a la sucursal seleccionada para realizar
                el retiro de tu pedido deberás estacionarte en la zona
                identificada para auto retiro y enviarnos un mensaje al whatsapp
                de la sucursal avisandonos que ya llegaste e informando el
                número de pedido que vas a retirar.
              </p>
              <p>
                El número de contacto de la sucursal lo puedes consultar en el
                mail de confirmación de tu pedido o en la cartelería de la zona
                de retiro en el estacionamiento de la sucursal.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(6)}
            buttonLabel={
              '¿Qué pasa si no puedo pasar a retirar mi pedido en el horario seleccionado?'
            }
          >
            <p>
              No te preocupes, si vas a pasar ese mismo día tu pedido te estará
              esperando en la sucursal acordada. En caso de que no puedas
              concurrir dentro del mismo día te solicitamos que te comuniques
              con nosotros a través del 08008282 para avisarnos cuándo estarás
              pasando a retirar tu pedido.
            </p>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(7)}
            buttonLabel={
              '¿Qué pasa si no me encuentran en el domicilio de entrega en el horario acordado?'
            }
          >
            <div>
              <p>
                En caso de que no podamos localizar te el chofer se comunicará
                contigo para poder avisarte que llegamos, y si no puedes
                recibirlo nos volveremos a contactar contigo para coordinar
                nuevamente el envío de tu pedido.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(8)}
            buttonLabel={
              'Necesito cambiar la fecha o el horario de entrega de mi pedido ¿Qué puedo hacer?'
            }
          >
            <div>
              <p>
                Si necesitas cambiar la fecha de entrega o el horario
                seleccionado podés comunicarte con nuestro equipo de atención al
                cliente al 08008282, informando el número de pedido y para
                cuándo necesitas re coordinar tu entrega.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(9)}
            buttonLabel={
              'Necesito modificar la dirección de entrega de mi pedido ¿Qué puedo hacer?'
            }
          >
            <div>
              <p>
                No te preocupes, en caso de que necesites cambiar la dirección
                de entrega de tu pedido podés comunicarte con nuestro equipo de
                atención al cliente al 08008282, informando el número de pedido
                y la nueva dirección de entrega.
              </p>
              <p>
                Tené en cuenta que esto puede generar un retraso de la entrega
                con respecto a lo seleccionado al momento de la compra.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(10)}
            buttonLabel={'¿Cómo pago mi pedido?'}
          >
            <div>
              <ul>
                <li>
                  Podes optar por abonar tu pedido de forma online con tarjetas:
                  Crédito: Master, Lider, Diners, Visa, Midinero, OCA o Prex.
                  Débito: Visa o OCA Blue
                </li>
                <li>
                  O pagar en el momento de la entrega con cualquiera de las
                  siguientes tarjetas: Tarjetas BROU crédito, débito y prepagas,
                  Tarjetas Midinero, OCA tarjetas de crédito y débito, Lider,
                  Mastercard, Diners Club, American express, Anda, Pass card,
                  Cabal y Creditel.
                </li>
                <li>
                  Ahora podés realizar el pago hasta en dos cuotas con tarjetas
                  de crédito mencionadas.
                </li>
              </ul>
              <span>
                Recordá que no aceptamos pagos en efectivo o transferencias
                bancarias y de momento no contamos con financiación en cuotas.
              </span>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(11)}
            buttonLabel={
              'Tengo un cupón de descuento, ¿Cómo puedo aplicarlo para descontar en mi pedido?'
            }
          >
            <div>
              <p>
                Para descontar un cupón promocional deberás ingresar el código
                en tu carrito en la casilla que indica “Código” y luego
                aplicarlo con el botón “Agregar”.
              </p>
              <p>
                Recordá leer las condiciones de uso del cupón, ya que algunos
                aplican en base a montos mínimos, son válidos por única vez o
                solo para descontar en la primera compra web.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(12)}
            buttonLabel={
              '¿Puedo guardar mi carrito para finalizar mi compra más tarde?'
            }
          >
            <div>
              <p>
                Sí, podes guardar tu carrito en una lista para poder finalizarla
                luego o inclusive para poder repetir la misma compra en el
                futuro.
              </p>
              <p>
                Para ello solo deberás clickear el botón “Guardar carrito” que
                verás en el resumen de tu carrito.
              </p>
              <p>
                Puedes guardarlo en una lista existente o crear una lista de
                compras nueva con los productos de tu carrito. Esta lista podrás
                editarla o inclusive cargarla a tu carrito en otro dispositivo.
              </p>
              <p>
                Tené en cuenta que lo que se guardan son los productos y sus
                cantidades, los precios, promociones o disponibilidad pueden
                variar respecto a cuando creaste tu lista.
              </p>
              <p>
                Cuando quieras volver a cargar tu lista en tu carrito podés
                ingresar a la sección “Mis Listas” en tu cuenta. Recordá que al
                agregar productos desde tu lista se sumarán a los que ya tengas
                dentro de tu carrito.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(13)}
            buttonLabel={
              'Tengo un inconveniente con mi compra ¿Qué puedo hacer?'
            }
          >
            <div>
              <p>
                Podes comunicarte con nuestro equipo de atención al cliente a
                través del chat en el sitio, telefónicamente al 08008282 o por
                correo electrónico a{' '}
                <a href="">servicioalcliente@tata.com.uy.</a>
              </p>
              <p>
                No dudes en contactarnos, estaremos felices de poder ayudarte.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            isExpanded={indicesExpanded.has(14)}
            buttonLabel={
              'Necesito hacer un cambio o devolver productos de mi compra'
            }
          >
            <div>
              <p>
                Podés consultar nuestra política de cambios y devoluciones en el
                siguiente{' '}
                <a href="https://www.tata.com.uy/institucional/cambios-y-devoluciones">
                  link.
                </a>
              </p>
            </div>
          </AccordionItem>
        </Accordion>
      </section>

      <div className="newsletter-container-inst-page">
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />{' '}
      </div>
      <StockAvailable icon />
    </>
  )
}

export const query = graphql`
  query LandingPageFAQQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage(
      filter: {
        seo: {
          siteMetadataWithSlug: { slug: { eq: "/lp/preguntas-frecuentes" } }
        }
      }
    ) {
      edges {
        node {
          seo {
            siteMetadataWithSlug {
              slug
            }
          }
          name
          sections {
            data
          }
        }
      }
    }
  }
`

export default Page

export { Head } from 'src/components/common/Head'
